import React from 'react';
import { Typography } from '@mui/material';

const PJvsCLTSimulator = () => {
  return (
    <Typography variant="h5">
      Simulador PJ vs CLT (Em desenvolvimento)
    </Typography>
  );
};

export default PJvsCLTSimulator;